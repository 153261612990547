@charset "utf-8";
@import "settings/vars";
@import "settings/mixins";
@import "base/reset";
@import "base/base";
@import "contents/page";


//mobile
@media only screen and (max-width: $BREAK_SP){
	@import "responsive/sp"; 
	#debug:before{content:"sp";}
}