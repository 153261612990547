.spdisp{display: block;}
.sphide{display: none;}

body{
	font-size: 1.2rem;
}

header{
	width: 100%;
	h1{margin-bottom: 30px;}
	h1 img{width: 60%;height: auto;}
}