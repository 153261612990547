//page
a{color:$BLUE;text-decoration: none;
	&:hover{text-decoration: underline;
		img{opacity:0.7; transition: all 0.3s $ease7;}
	}
}

.center {	text-align: center;}
.spdisp{display: none;}


// ラッパー
body{
	height: 100vh;
}
#wrapper{
	width: 100%;height: 100%;min-height:550px;text-align: center;
}

// ヘッダー
header{
	position: absolute;top:50%;left: 50%;transform: translate(-50%,-50%);
	.corpname{margin-top: 50px;font-weight: bold;font-size: 1.8rem;color: $BLACK;}
	.link{margin-top: 20px;@extend .roboto;}
}
footer{
	position: absolute;bottom:20px;width: 100%;
	.copyright{font-size: 1.0rem;@extend .roboto;}
}

//トップページ
#home{


}


