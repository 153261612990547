//base
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*{box-sizing:border-box; word-break: break-all;}
html{
	-webkit-text-size-adjust: none;
	font-size: 62.5%;
}

img{vertical-align: bottom;}
ol{padding-left:20px; list-style-type:decimal;}

strong{	font-weight: bold;}
sup,sub{height: 0; line-height: 1; vertical-align: baseline;  _vertical-align: bottom; position: relative; font-size:1rem;}
sup{bottom: 1ex;}
sub{top: .5ex;}

body{
	font-size:1.4rem;font-family:$FONT_FAMILY; color:$FONT_COLOR;line-height: $LINE_HEIGHT;
}

.roboto{font-family: 'Roboto', sans-serif;font-weight: 400;}